import store from '@/store';
import firebase from "firebase/app";
import router from "@/router";
import {remove} from "@/services/storage";

export default {
    data() {
        return {
            state: store.state,
        }
    },
    methods: {
        async login(payload) {
            firebase.auth().signInWithEmailAndPassword(
                payload.email,
                payload.password
            ).then((userCredential) => {
                this.state.user = userCredential.user
                router.replace("/tabs/app");
            }).catch((error) => {
                //console.log(error.code, error.message);
                this.state.loginError = error.message;
                //throw new Error(error);
            });
        },
        async signup(payload) {
            firebase.auth().createUserWithEmailAndPassword(
                payload.email,
                payload.password
            ).then((userCredential) => {
                // Signed in
                this.state.user = userCredential.user
                    .updateProfile({
                        displayName: payload.name
                    })
                router.replace("/tabs/app");
            }).catch((error) => {
                //console.log(error.code, error.message);
                this.state.loginError = error.message;
                //throw error;
            });
        },
        async logout() {
            firebase.auth().signOut()
                .then(() => {
                    router.replace("/login");
                    this.state.user = null;
                    remove('kuricrimp');
                    remove('kurikrimp');
                    remove('piranhaflex');
                    //console.log('Logout');
                });
        },
        async passwordReset(payload) {
            firebase.auth().sendPasswordResetEmail(
                payload.email
            ).then(() => {
                console.log('Password Reset sent');
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
        },
    },
    computed: {
        async isLoggedIn() {
            return !!this.state.user;
        },
        async fetchUser() {
            return this.state.user;
        },
    }
}
