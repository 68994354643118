<template>
  <div class="span_12_of_12" id="corporatebanner">
            <div class="span_10_of_12">
              <img class="visible-sm" src="https://www.kuriyama.com/img/KOA-badge.svg" alt="Kuriyama of America, Inc."><a href="https://www.kuriyama.com"><img src="https://www.kuriyama.com/img/Kuriyama-of-America-horz-neg.svg" alt="Kuriyama of America, Inc." class="corp-logo"></a>

                <ul class="right-nav">
                    <li><a href="https://www.kuriyama.com/about.php" target="_blank">ABOUT</a></li>
                    <li id="companies-parent"><a href="#">COMPANIES <span class="icon-sort-down"></span></a>
                        <div id="companies">
                          <div class="inner-companies">
                            <ul>
                                <li><a href="https://www.kuriyama.com" target="_blank"><img src="https://www.kuriyama.com/img/companies-koa.png" alt="Kuriyama of America, Inc."></a></li>
                                <li><a href="https://www.accuflex.com" target="_blank"><img src="https://www.kuriyama.com/img/companies-acfx.png" alt="Accuflex Industrial Hose, Ltd."></a></li>
                                <li><a href="https://www.hosetec.com" target="_blank"><img src="https://www.kuriyama.com/img/companies-hstc.png" alt="Hosetec"></a></li>
                                <li><a href="https://www.kuritec.com" target="_blank"><img src="https://www.kuriyama.com/img/companies-ktc.png" alt="Kuritec Corporation"></a></li>
                                <li><a href="https://www.kuriyama.com/about-kuriyama-de-mexico.php?type=group" target="_blank"><img src="https://www.kuriyama.com/img/companies-kom.png" alt="Kuriyama De Mexico"></a></li>
                                <li><a href="https://www.kuriyamafireproducts.com" target="_blank"><img src="https://www.kuriyama.com/img/companies-kfp.png" alt="Kuriyama Fire Products"></a></li>
                                <li><a href="https://www.piranhahose.com" target="_blank"><img src="https://www.kuriyama.com/img/companies-php.png" alt="Piranha Hose Products, Inc."></a></li>
                                <li><a href="http://www.tipsa.com" target="_blank"><img src="https://www.kuriyama.com/img/companies-tipsa.png" alt="Tipsa"></a></li>
                            </ul>
                          </div>
                        </div>
                    </li>
                </ul>
               
            </div>
        </div>
  <ion-header class="mkc-global-header ion-text-center">
    <ion-toolbar class="mkc-global-toolbar">
      <ion-title class="mkc-global-title" part="title">My <span>KuriCrimp</span></ion-title>
    </ion-toolbar>
    <desktop-navigation-bar v-if="state.layout==='desktop' && state.user"></desktop-navigation-bar>
  </ion-header>
</template>

<script>
import {IonHeader, IonTitle, IonToolbar} from '@ionic/vue';
import store from "@/store";
import DesktopNavigationBar from "@/components/DesktopNavigationBar";

export default {
  name: "Header",
  components: {IonHeader, IonTitle, IonToolbar, DesktopNavigationBar},
  data() {
    return {
      state: store.state
    }
  }
}
</script>

<style scoped>
ion-toolbar::part(background) {
    background-color: #29569a;
}
</style>
