<template>
  <div class="mkc-desktop-header-bar">
    <div class="external-links">
      <ul>
        <li>
          <router-link to="/tabs/app" title="Home">Home</router-link>
        </li>
        <!--<li><a href="/about">About</a></li>
        <li><a href="/locations">Locations</a></li>-->
        <!--<li><a href="/support" title="Support">Support</a></li>-->
      </ul>
    </div>
    <div class="app-links">
      <ul>
        <li>
          <router-link to="/tabs/user" title="Your Profile">
            <ion-icon :icon="person"/>
            My Account
          </router-link>
        </li>
        <li>
          <router-link to="/tabs/favourites" title="Favorites">
            <ion-icon :icon="heart"/>
            Favorites
          </router-link>
        </li>
        <li>
          <a @click="toggleHelp" title="Help" :class="state.showHelp?'active':''">
            <ion-icon :icon="helpCircle" :color="state.showHelp?'primary':''"/>
            {{helpToastMessage}}
          </a>
        </li>
      </ul>
    </div>
  </div>
  <ion-toast
      :is-open="isHelpToastOpen"
      :message="helpToastMessage"
      :duration="1000"
      position="top"
      @didDismiss="setToastOpen(false)"
  >
  </ion-toast>
</template>

<script>
import {IonIcon, IonToast} from '@ionic/vue';
import {defineComponent} from 'vue';
import {person, heart, triangle, trash, chevronBackCircle, helpCircle} from 'ionicons/icons';
import store from "@/store";

export default defineComponent({
  name: "DesktopNavigationBar",
  components: {IonIcon, IonToast},
  setup() {
    return {
      person,
      heart,
      triangle,
      trash,
      chevronBackCircle,
      helpCircle
    }
  },
  data() {
    return {
      state: store.state,
      isHelpToastOpen: false
    }
  },
  methods: {
    toggleHelp() {
      store.setShowHelp();
      this.setToastOpen(true);
    },
    setToastOpen(state) {
      this.isHelpToastOpen = state;
    }
  },
  computed: {
    helpToastMessage() {
      return (this.state.showHelp) ? 'Help on' : 'Help off'
    },
  }
});
</script>

<style scoped>

</style>
